<template>
  <v-container>
    <!-- keyboard -->
    <Drop @drop="handleDropOutside($event)">
      <v-row id="keyboard" justify="center">
        <v-col :style="{ maxWidth: '400px' }">
          <v-row justify="center" v-if="complete" dense>
            <v-col cols="auto">
              <v-row dense
                ><v-col :style="{ textAlign: 'center', fontSize: 'x-large' }">{{
                  $t(
                    'Congratulations for successfully completing this exercise!'
                  )
                }}</v-col></v-row
              >
              <v-row dense
                ><v-col class="reportCardLabel">{{ $t('Level') }}</v-col
                ><v-col class="reportCardValue">{{
                  this.selectedLevel
                }}</v-col></v-row
              >
              <v-row dense
                ><v-col class="reportCardLabel">{{
                  $t('Number of Questions')
                }}</v-col
                ><v-col class="reportCardValue">{{
                  this.totalNumberOfQuestions
                }}</v-col></v-row
              >
              <v-row dense
                ><v-col class="reportCardLabel">{{
                  $t('Correct Answers')
                }}</v-col
                ><v-col class="reportCardValue">{{
                  correctCount
                }}</v-col></v-row
              >
              <v-row dense
                ><v-col class="reportCardLabel">{{ $t('Score') }}</v-col
                ><v-col class="reportCardValue">{{ finalScore }}%</v-col></v-row
              >
            </v-col>
          </v-row>
          <!-- play again -->
          <v-row no-gutters justify="center" class="pa-2">
            <v-col
              v-if="complete"
              class="pa-1"
              cols="auto"
              style="text-align: center"
            >
              <span>
                <v-btn
                  raised="true"
                  color="secondary"
                  @click="practiceAgain()"
                  >{{ $t('Play again') }}</v-btn
                >
              </span>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="!complete" dense>
            <v-col>
              <v-row justify="center">
                <v-col
                  class="pr-1 pl-1"
                  cols="2"
                  v-for="(c, i) in answer"
                  :key="answer + i"
                >
                  <Drop @drop="handleDrop($event, i)">
                    <Drag :transfer-data="{ letter: c, fromInputBoxIndex: i }">
                      <input
                        :class="submissionWordClass"
                        type="text"
                        minlength="1"
                        required="required"
                        :value="c"
                        :readonly="true"
                        @click="onWordClear(i)"
                      />
                    </Drag>
                  </Drop>
                </v-col>
              </v-row>
              <v-row justify="center">
                <span class="text-h5"
                  >{{
                    `${this.selectedWord.hindi} - ${this.selectedWord.english}`
                  }}
                  <span v-if="showHint" class="text-h5 pl-1">
                    {{ `(${selectedWord.eng_guj})` }}</span
                  >
                </span>
              </v-row>
              <v-row justify="center">
                <HowToPlay :i18n_instructions_key="'buildWordInstruction'" />
              </v-row>
              <v-row justify="center">
                <span
                  :style="{ textAlign: 'center' }"
                  class="blink"
                  v-if="tryagain"
                  >{{ $t('Try Again') }}!
                </span>
              </v-row>
              <v-row justify="center">
                <v-col
                  class="pa-1"
                  v-for="(l, key) in letters"
                  :key="l.guj + key"
                >
                  <LetterButton
                    :letter="l.guj"
                    :game-over="false"
                    :draggable="true"
                    :key="l.guj"
                    @check="check(letter)"
                    v-cloak
                  />
                </v-col>
                <v-col
                  class="pa-1"
                  v-for="(v, key) in vowels"
                  :key="v.guj + key"
                >
                  <LetterButton
                    :letter="v.guj"
                    :game-over="false"
                    :draggable="true"
                    :key="v.guj"
                    @check="check(letter)"
                    v-cloak
                  />
                </v-col>
                <v-col
                  class="pa-1"
                  v-for="(v, key) in vowels"
                  :key="v.symbol + key"
                >
                  <LetterButton
                    :letter="v.symbol"
                    :game-over="false"
                    :draggable="true"
                    :key="v.symbol"
                    @check="check(letter)"
                    v-cloak
                  />
                </v-col>
              </v-row>
              <v-row justify="start" align="center">
                <v-col cols="8" class="pr-0 mr-0">
                  {{ $t('Join two letters using this') }}:
                </v-col>
                <v-col class="pa-1">
                  <LetterButton
                    letter="્"
                    :game-over="false"
                    :draggable="true"
                    @check="check(letter)"
                    v-cloak
                  />
                </v-col>
              </v-row>
              <v-row
                class="pa-2"
                justify="center"
                v-if="questionSubmitted && !correctAnswerSubmitted && !complete"
              >
                <!-- possible answer -->
                <v-card shaped>
                  <div
                    :style="{
                      'background-color': '#f5f5f5',
                      padding: '0.5em 0 0 0.5em',
                      'text-align': 'center'
                    }"
                  >
                    {{ $t('Correct Answer') }}&nbsp;
                  </div>
                  <hr />
                  <v-card-title style="color: green" class="pa-2">
                    {{ selectedWord.word.join('') }}
                  </v-card-title>
                </v-card>
              </v-row>
              <v-row
                align="center"
                justify="center"
                class="pa-2"
                v-if="practiceCount > 0"
              >
                <v-btn
                  v-if="!complete && !questionSubmitted"
                  raised
                  color="primary"
                  @click="submitAnswer()"
                  >{{ $t('Submit Answer') }}</v-btn
                >
                <v-btn
                  v-if="!complete && questionSubmitted"
                  raised="true"
                  color="secondary"
                  @click="nextQuestion"
                  >{{ $t('Next Question') }}</v-btn
                >
              </v-row>
              <v-row v-if="practiceCount === 0" align="center" justify="center">
                <span
                  :style="{
                    textAlign: 'center',
                    color: $vuetify.theme.themes.light.primary
                  }"
                  class="text-h6 mr-3"
                  >Your skill level is:
                </span>
                <v-icon
                  v-for="i in skillRating"
                  :key="'star' + i"
                  :color="i.color"
                >
                  {{ i.icon }}
                </v-icon>
              </v-row>
              <v-row v-else align="center" justify="center">
                <Scorecard
                  :hidePracticeCount="true"
                  :correctAttempts="correctCount"
                  :wrongAttempts="
                    currentQuestionIndex +
                    (questionSubmitted ? 1 : 0) -
                    correctCount
                  "
                  :totalAttempts="totalNumberOfQuestions"
                />
              </v-row>
              <v-row
                v-if="practiceCount === 0"
                align="center"
                justify="center"
                class="mt-2"
              >
                <v-btn
                  raised="true"
                  color="secondary"
                  @click="practiceAgain()"
                  >{{ $t('Try Again') }}</v-btn
                >
              </v-row>
              <v-row align="center">
                <v-col>
                  <v-btn color="secondary" text @click="showHint = !showHint">
                    Hint {{ showHint ? $t('ON') : $t('OFF') }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-select
                    v-model="selectedLevel"
                    :items="levels"
                    item-text="text"
                    item-value="value"
                    :rules="[(v) => !!v || 'Item is required']"
                    @change="refresh()"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <GoBackButton />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </Drop>
  </v-container>
</template>

<script>
import GoBackButton from './GoBackButton'
import LetterButton from './DraggableLetterButton'
import { Drag, Drop } from 'vue-drag-drop'
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour'
import Utils from '../util/Utils.js'
import Scorecard from './Scorecard'
import HowToPlay from './HowToPlay'

export default {
  mounted() {
    this.refresh()
    this.reset()
  },
  components: {
    LetterButton,
    Drag,
    Drop,
    GoBackButton,
    Scorecard,
    HowToPlay
  },
  computed: {
    letters() {
      return this.$store.state.letters
    },
    vowels() {
      return this.$store.state.vowels
    },
    submissionWordClass() {
      if (!this.questionSubmitted) {
        return 'kakko-input-blank'
      }
      return this.correctAnswerSubmitted
        ? 'kakko-input-blank correctAnswerCell'
        : 'kakko-input-blank incorrectAnswerCell'
    }
  },
  methods: {
    handleDropOutside(data) {
      if (data.fromInputBoxIndex >= 0) {
        this.answer[data.fromInputBoxIndex] = ''
        this.$forceUpdate()
      }
    },
    deleteChar() {},
    gujaratiWordSeperator: Utils.gujaratiWordSeperator,
    random(number) {
      return Math.floor(Math.random() * number)
    },
    refresh() {
      const words = Object.keys(this.$store.state.wordList[this.selectedLevel])
      const word = words[this.random(words.length)]
      let temp = this.gujaratiWordSeperator(word.split(''))
      temp = this.gujaratiWordSeperator(temp)
      this.selectedWord = {
        word: temp,
        ...this.$store.state.wordList[this.selectedLevel][word]
      }
      this.answer = temp.map((w) => '')
    },
    reset() {
      this.totalNumberOfQuestions = Object.keys(
        this.$store.state.wordList[this.selectedLevel]
      ).length
      this.practiceCount = this.totalAttempts
      this.wrongAttempts = 0
      this.skillRating = []
      this.correctAnswerSubmitted = false
      this.questionSubmitted = false
    },
    practiceAgain() {
      this.currentQuestionIndex = 0
      this.complete = false
      this.correctCount = 0
      this.refresh()
      this.reset()
    },
    onWordClear(index) {
      if (this.questionSubmitted) return
      this.answer[index] = ''
      this.$forceUpdate()
    },
    submitAnswer() {
      this.questionSubmitted = true
      this.practiceCount--
      if (this.answer.join('') === this.selectedWord.word.join('')) {
        this.correctAnswerSubmitted = true
        this.correctCount++
        if (this.practiceCount === 0) {
          let score = this.totalAttempts - this.wrongAttempts
          this.skillRating = []
          while (score >= this.totalAttempts / 5) {
            this.skillRating.push({
              icon: 'mdi-star',
              color: this.$vuetify.theme.themes.light.secondary
            })
            score = score - this.totalAttempts / 5
          }
          if (score >= this.totalAttempts / 10) {
            this.skillRating.push({
              icon: 'mdi-star-half-full',
              color: this.$vuetify.theme.themes.light.secondary
            })
          }
          while (this.skillRating.length < 5) {
            this.skillRating.push({ icon: 'mdi-star-outline', color: '' })
          }
          if (
            this.skillRating.filter((s) => s.icon === 'mdi-star-outline')
              .length <= 1
          ) {
            this.confettiDrop()
          }
        } else {
          // this.confettiDrop()
        }
      } else {
        this.correctAnswerSubmitted = false
        this.wrongAttempts++
      }
    },
    nextQuestion() {
      this.totalNumberOfQuestions = Object.keys(
        this.$store.state.wordList[this.selectedLevel]
      ).length
      const allQuestionsAttempted =
        this.currentQuestionIndex + 1 === this.totalNumberOfQuestions
      if (allQuestionsAttempted) {
        this.complete = true
        this.finalScore =
          (this.correctCount / this.totalNumberOfQuestions).toFixed(2) * 100
        if (this.finalScore >= 70) {
          this.confettiDrop()
        }
      }
      this.currentQuestionIndex++
      this.refresh()
      this.reset()
      this.$forceUpdate()
    },
    confettiDrop() {
      Utils.dropConfetti(this.$confetti)
    },
    handleDrop(data, index) {
      if (data.fromInputBoxIndex >= 0) {
        this.answer[index] = this.answer[data.fromInputBoxIndex]
        this.answer[data.fromInputBoxIndex] = ''
      } else {
        this.answer[index] += data.letter
      }
      this.$forceUpdate()
    }
  },
  data: (vm) => ({
    currentQuestionIndex: 0,
    correctAnswerSubmitted: false,
    questionSubmitted: false,
    totalNumberOfQuestions: 0,
    correctCount: 0,
    complete: false,
    totalAttempts: 50,
    skillRating: [],
    practiceCount: 0,
    wrongAttempts: 0,
    dialog: false,
    selectedWord: '',
    answer: '',
    tryagain: false,
    showHint: false,
    selectedLevel: 'level1',
    levels: [
      { text: vm.$t('Level') + ' 1', value: 'level1' },
      { text: vm.$t('Level') + ' 2', value: 'level2' },
      { text: vm.$t('Level') + ' 3', value: 'level3' }
    ]
  })
}
</script>
<style>
.correctAnswerCell {
  background-color: green;
  color: white;
}
.incorrectAnswerCell {
  background-color: red;
  color: white;
}
.clearAnswerCell {
  background-color: white;
  color: black;
}
.reportCardLabel {
  text-align: right;
  font-size: large;
  border-right: 1px solid;
  font-weight: bold;
}
.reportCardValue {
  font-size: large;
  border-left: 1px solid;
  font-weight: bold;
}
.letter {
  padding: 1px;
  margin: 5px;
  text-shadow: 0 1px 1px #eee;
  font: normal 40px 'Lucida Sans Unicode', 'Lucida Grande', 'Arial Unicode MS',
    sans-serif;
}

/* D. keyboard */
#keyboard {
  margin-left: 2px;
  margin-right: 2px;
}
.keyboardWidth {
  max-width: 400px;
}
.keyboard-row-letter {
  height: 40px;
  width: 30px;
  font-size: x-large;
}
.kakko-input-valid {
  background: green;
}
.kakko-input-invalid {
  background: red;
}
.blink {
  animation: blink 1s linear infinite;
  color: red;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
